// -----------------------------------------------------------------------------
// generics/base
// -----------------------------------------------------------------------------

* {
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-variant-ligatures: none;

    @apply .antialiased .scrolling-touch;
}

html,
body {
    height: 100%;
}
