.c-language-nav-wrapper {
    $c: &;

    @apply .relative .bg-white;

    &:after {
        left: 100%;

        content: '';

        @apply .absolute .pin-t .pin-b .w-screen .h-full .bg-white;
    }
}
