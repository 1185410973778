.c-link {
    $c: &;

    &__label {
        @apply .text-sm .transition;
    }

    &__icon {
        @apply .transition;
    }

    &--primary {
        #{$c} {
            &__label {
                @apply .text-primary .border-b .border-primary-30;
            }

            &__icon {
                @apply .text-primary;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .border-primary-80;
                }

                &__icon {
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__label {
                @apply .text-secondary .border-b .border-secondary-30;
            }

            &__icon {
                @apply .text-secondary;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .border-secondary-80;
                }

                &__icon {
                }
            }
        }
    }
}
