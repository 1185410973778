.c-nav {
    $c: &;

    @apply .text-0;

    &__list {
    }

    &__item {
        @apply .relative;
    }

    &__link {
    }

    &__label {
        @apply .transition;
    }

    &--primary {
        #{$c} {
            &__list {
            }

            &__item {
                @apply .inline-block .pl-5 .ml-5;

                &:before {
                    top: 50%;
                    left: 0;

                    content: '';
                    transform: translate(-50%, -50%);

                    @apply .absolute .w-3 .h-px .bg-primary;
                }

                &:first-child {
                    @apply .pl-0 .ml-0;

                    &:before {
                        @apply .hidden;
                    }
                }

                &.is-active {
                    #{$c} {
                        &__link {
                        }

                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xsm .font-light .font-secondary .text-black .tracking-1px .uppercase .leading-none;

                @screen xl{
                    @apply .text-sm;
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__list {
                @apply .-ml-6;
            }

            &__item {
                @apply .inline-block .pl-6;

                &.is-active {
                    #{$c} {
                        &__link {
                        }

                        &__label {
                            @apply .text-tertiary-darker;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-tertiary-darker;
                        }
                    }
                }
            }

            &__label {
                @apply .uppercase .text-1xs .font-light .font-secondary .text-tertiary-dark .leading-none;
            }
        }
    }

    &--language {
        #{$c} {
            &__list {
                @apply .-ml-3;
            }

            &__item {
                @apply .inline-block .pl-3;

                &.is-active {
                    #{$c} {
                        &__link {
                        }

                        &__label {
                            @apply .text-primary .font-bold;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .uppercase .text-1xs .font-light .font-secondary .text-secondary .leading-none;
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {
                @apply .-mb-1;

                @screen md {
                    @apply .-mb-0;
                }
            }

            &__item {
                @apply .relative .block .pb-1;

                @screen md {
                    @apply .inline-block .pl-2.5 .ml-2.5 .pb-0;

                    &:before {
                        top: 50%;
                        left: 0;

                        width: 3px;
                        height: 3px;

                        content: '';
                        transform: translate(-50%, -50%);

                        @apply .absolute .bg-tertiary-darker .rounded-full;
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-secondary-80;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xs .font-bold .font-secondary .text-tertiary-darker .border-b .border-secondary-30;
            }
        }
    }

    &--social {
        #{$c} {
            &__list {
                @apply .-ml-2 .-mb-2;
            }

            &__item {
                @apply .inline-block .pl-2 .pb-2 .align-top;
            }

            &__link {
                @apply .w-10 .h-10 .flex .items-center .justify-center .bg-black .text-white;

                &:hover,
                &:active {
                    @apply .bg-primary;
                }
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__list {
            }

            &__item {
                @apply .inline-block .pl-1.5 .ml-1.5;

                &:before {
                    top: 50%;
                    left: 0;

                    content: '›';
                    transform: translate(-50%, -50%);

                    @apply .absolute .font-secondary .text-primary .font-medium .text-1xs;
                }

                &:first-child {
                    @apply .pl-0 .ml-0;

                    &:before {
                        @apply .hidden;
                    }
                }

                &:last-child {
                    #{$c} {
                        &__label {
                            @apply .font-normal;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-primary-20;
                        }
                    }
                }
            }

            &__label {
                @apply .text-1xs .font-medium .font-secondary .text-primary .border-b .border-transparent;
            }
        }
    }
}
