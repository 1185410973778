.c-text {
    $c: &;

    @apply .text-sm;

    a {
        @apply .text-primary .border-b .border-primary-30;

        &:hover,
        &:active {
            @apply .border-primary-80;
        }
    }

    p {
        @apply .mb-7;

        &:last-child {
            @apply .mb-0;
        }
    }

    ul {
        @apply .mb-7;

        &:last-child {
            @apply .mb-0;
        }

        li {
            @apply .relative .pl-3 .mb-1;

            &:before {
                top: 10px;

                width: 3px;
                height: 3px;

                content: '';

                @apply .bg-tertiary .rounded-full .absolute .pin-l;
            }

            &:last-child {
                @apply .mb-0;
            }
        }
    }

    h2 {
        @apply .mb-3;
    }

    h3 {
        @apply .mb-2;
    }

    h4 {
        @apply .mb-1.5;
    }
}
