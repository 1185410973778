.c-icon {
    $c: &;

    @apply .fill-current .transition;

    &--facebook {
        width: 8px;
        height: 16px;
    }

    &--instagram {
        width: 16px;
        height: 16px;
    }

    &--twitter {
        width: 16px;
        height: 16px;
    }

    &--linkedin {
        width: 16px;
        height: 16px;
    }

    &--pinterest {
        width: 16px;
        height: 16px;
    }

    &--youtube {
        width: 16px;
        height: 16px;
    }

    &--arrow {
        width: 4px;
        height: 8px;
    }

    &--arrow-small {
        width: 3px;
        height: 6px;
    }

    &--download {
        width: 13px;
        height: 12px;
    }

    &--check {
        width: 8px;
        height: 7px;
    }
}
