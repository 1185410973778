.c-specs {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .-mb-4;
    }

    &__item {
        @apply .relative .pl-4 .text-secondary .font-bold .text-sm .leading-none .pb-4 .font-primary;

        &:before {
            top: 6px;

            width: 4px;
            height: 4px;

            content: '';

            @apply .bg-secondary .rounded-full .absolute .pin-l;
        }
    }
}
