.c-gallery {
    height: 370px;

    $c: &;

    @apply .relative .text-0;

    &__list {
        @apply .h-full;
    }

    &__item {
        @apply .h-full;
    }

    &__link {
        @apply .transition .flex .h-full .border .border-tertiary-lighter .p-6;

        &:hover,
        &:active {
            @apply .border-secondary;

            #{$c} {
                &__indicator {
                    @apply .bg-secondary;
                }
            }
        }
    }

    &__image {
        @apply .object-contain .object-center;
    }

    &__indicator {
        @apply .absolute .pin-r .pin-b .bg-tertiary-lighter .text-white .text-sm .w-10 .h-10 .flex .items-center .justify-center .font-secondary .font-medium;
    }

    &--full {
        @apply .h-auto;

        #{$c} {
            &__list {
                @apply .h-auto;
            }

            &__item {
                @apply .h-auto;
            }

            &__link {
                @apply .block .h-auto .p-0;
            }

            &__image {
                @apply .object-none;
            }
        }
    }
}
