.c-content-builder {
    $c: &;

    @apply .w-full;

    &__row {
        @apply .mb-12;

        &:last-child {
            @apply .mb-0;
        }

        &--heading {
            @apply .mb-8;
        }
    }
}
