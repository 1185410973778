.c-product {
    $c: &;

    @apply .w-full;

    &__figure {
        height: 200px;

        @apply .relative .border .border-tertiary-lighter .flex .justify-center .transition;
    }

    &__image {
        @apply .object-scale-down .object-center .transition .w-full .h-full .p-4;
    }

    &__body {
        @apply .mt-3;
    }

    &__button {
        top: 80px;
        left: 50%;

        transform: translate(-50%, -50%);

        @apply .absolute .inline-block .p-1.5 .bg-primary .text-white .transition .opacity-0 .uppercase .text-1xs .font-light .tracking-1px;
    }

    &:hover,
    &:active {
        #{$c} {
            &__figure {
                @apply .border-secondary;
            }

            &__image {
                filter: blur(3px);
            }

            &__button {
                top: 50%;

                @apply .opacity-100;
            }
        }
    }
}
