.c-content {
    $c: &;

    &__section {
        @apply .relative;

        &--home-hero {
            padding-top: 10px;

            &:before {
                top: 0;
                right: 0;
                left: 0;

                height: 128px;

                content: '';

                @apply .absolute .bg-tertiary-lightest .w-full;
            }
        }

        &--social-feed {
            background-image: url('/images/social-bg.jpg');

            @apply .bg-center .bg-repeat;
        }
    }
}
