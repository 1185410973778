.c-google-map {
    height: 430px;

    $c: &;

    @apply .w-full;

    .gm-style-cc {
        @apply .hidden;
    }
}
