.c-button {
    $c: &;

    @apply .py-5 .px-6;

    &__label {
        @apply .text-sm .transition .tracking-1px .uppercase .leading-none;
    }

    &--primary {
        @apply .bg-primary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-secondary; 
        }
    }

    &--secondary {
        @apply .bg-tertiary-lightest;

        #{$c} {
            &__label {
                @apply .text-tertiary-darkest .font-light;
            }
        }

        &:hover,
        &:active {
            @apply .bg-secondary;

            #{$c} {
                &__label {
                    @apply .text-white;
                }
            }
        }
    }

    &--xs {
        @apply .p-1.5;

        #{$c} {
            &__label {
                @apply .text-1xs;
            }
        }
    }
}
