// -----------------------------------------------------------------------------
// vendors/snazzy-info-window
// -----------------------------------------------------------------------------

.si-content-wrapper {
    @apply .p-8;

    .si-content {
        @apply .overflow-visible;
    }
}

.si-close-button {
    @apply .text-right .pr-3 .pt-2;
}

@import '../../../node_modules/snazzy-info-window/dist/snazzy-info-window.scss';
