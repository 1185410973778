.c-social-feed-item {
    $c: &;

    @apply .p-12 .bg-white .block;

    &__heading {
        @apply .mt-6;
        
        @screen md {
            @apply .my-0;
        }
        
        @screen lg {
            @apply .mb-10;
        }
    }

    &__text {
    }

    &__figure {
    }

    &__image {
    }

    &__date {
        @apply .text-primary .text-1xs .uppercase .font-secondary .font-light .tracking-1px;
    }

    &:hover,
    &:active {
        transform: scale(1.02);
    }
}
