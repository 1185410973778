.c-form-field {
    $c: &;

    &__label-wrapper {
        width: 130px;

        @apply .flex-no-shrink .mb-2;

        @screen lg {
            @apply .mb-0;
        }
    }

    &__input-wrapper {
        @apply .flex-grow;
    }

    &__label {
        @apply .relative .inline-block .text-sm .text-secondary;

        @screen lg {
            @apply .mt-3;
        }

        &.is-required {
            &:after {
                bottom: 0;
                left: auto;

                content: '*';

                @apply .absolute .pl-1 .text-base .text-secondary;
            }
        }
    }

    &__input {
        @apply .w-full .text-sm .leading-normal .text-secondary .p-3.5 .transition .border .border-tertiary-lighter;

        &:focus {
            border-color: config('colors.tertiary-light');
        }
    }

    &__errors {
        @apply .text-sm .text-error .mt-1.5;
    }

    &--checkbox,
    &--mailing_list {
        #{$c} {
            &__input {
                @apply .hidden;

                &:checked {
                    + #{$c} {
                        &__label {
                            .c-checkbox {
                                &__input {
                                    .c-icon {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__label {
                @apply .mt-0;
            }
        }
    }

    &.has-errors {
        #{$c} {
            &__input {
                @apply .border-error;
            }
        }
    }
}
