// -----------------------------------------------------------------------------
// elements/button
// -----------------------------------------------------------------------------

button {
    @apply .transition;

    &:focus {
        outline: none;
    }
}
