.c-product-category {
    $c: &;

    @apply .w-full .text-0;

    &__figure {
        height: 120px;

        @apply .relative .inline-flex .items-center;
    }

    &__image {
        @apply .max-h-full;
    }

    &__body {
        @apply .relative .mt-8 .pt-8;

        @scren md {
            @apply .mt-10;
        }

        &:before {
            left: 50%;

            width: 120px;
            height: 2px;

            content: '';
            transform: translate(-50%, -50%);

            @apply .absolute .bg-secondary .pin-t;

            @screen md {
                transform: translate(0, -50%);

                @apply .pin-l;
            }
        }
    }

    &__title {
    }
}
