.c-mobile-nav {
    position: fixed;
    top: 0;
    right: -100%;

    width: 100%;
    height: 100%;

    opacity: 1;

    @apply .flex .bg-secondary .z-20 .transition .p-20 .overflow-y-scroll .items-center .text-center;

    &__close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &__nav {
        @apply .w-full .flex;
    }

    &__list {
        @apply .w-full;
    }

    &__item {
        @apply .pb-4 .mb-4;
    }

    &.is-active {
        right: 0;

        @apply .transition;
    }

    .c-nav {
        @apply .block .h-auto;

        &--primary {
            .c-nav {
                &__list {
                    @apply .h-auto;
                }

                &__item {
                    @apply .pb-4 .mb-4 .pl-0 .block .h-auto .pl-0 .ml-0;

                    &:before {
                        top: auto;
                        bottom: 0;
                        left: 50%;

                        transform: translate(-50%, 50%);
                    }

                    &:first-child {
                        &:before {
                            @apply .block;
                        }
                    }

                    &:last-child {
                        @apply .pb-0 .mb-0;

                        &:before {
                            @apply .hidden;
                        }
                    }
                }

                &__label {
                    @apply .text-lg .text-white .inline-block;
                }
            }
        }

        &--secondary {
            .c-nav {
                &__list {
                    @apply .-mb-3 .ml-0 .h-auto;
                }

                &__item {
                    @apply .pb-3 .pl-0 .block .h-auto;

                    &.is-active {
                        .c-nav__label {
                            @apply .text-primary;
                        }
                    }
                }

                &__link {
                    &:hover,
                    &:active {
                        .c-nav__label {
                            @apply .text-primary;
                        }
                    }
                }

                &__label {
                    @apply .text-base .text-white .inline-block;
                }
            }
        }

        &--language {
            .c-nav {
                &__list {
                }

                &__item {
                }

                &__link {
                }

                &__label {
                    @apply .text-base .text-white .inline-block;
                }
            }
        }
    }
}
