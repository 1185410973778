.c-cta {
    $c: &;

    @apply .w-full .h-full .text-center .py-8 .px-4 .border .border-tertiary-lighter;

    &__icon {
        @apply .block .mb-6 .mx-auto .w-10 .h-10;
    }

    &__label {
        @apply .inline-block .uppercase .text-xbase .text-secondary .transition;
    }

    &:hover,
    &:active {
        @apply .bg-secondary .border-secondary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }
    }
}
