.c-cookie-consent {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateY(100%);

    $c: &;

    @apply .bg-secondary .text-center .p-4 .transition;

    &__content {
        @screen md {
            @apply .inline-flex .items-center .text-left;
        }
    }

    &__message {
        @apply .text-xsm .leading-normal .mb-4 .text-white;

        @screen md {
            @apply .flex-grow .mr-6 .mb-0;
        }

        a {
            @apply .text-white .border-b .border-white-40;

            &:hover,
            &:active {
                @apply .border-white;
            }
        }
    }

    &__dismiss {
        @screen md {
            @apply .flex-no-shrink
        }

        .c-button {
            @apply .p-3 .leading-none .rounded-none .bg-primary;

            &__label {
                @apply .text-sm .text-white;
            }

            @media (hover: hover) {
                &:hover,
                &:active {
                    @apply .bg-primary-90;

                    .c-button__label {
                        @apply .text-white;
                    }
                }
            }
        }
    }

    &.in-view {
        transform: translateY(0);
    }
}
