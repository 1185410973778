// -----------------------------------------------------------------------------
// elements/link
// -----------------------------------------------------------------------------

a {
    color: inherit;

    @apply .inline-block .no-underline .transition;

    &:focus {
        @apply .outline-none;
    }
}
