.c-hero {
    height: 380px;

    $c: &;

    @apply .relative .text-0;

    @screen md {
        height: 460px;
    }

    &__figure {
        @apply .h-full .bg-center .bg-cover .bg-no-repeat;
    }

    &__content {
        @apply .text-center .px-6 .max-w-xmd;
    }

    &__title {
        text-shadow: 0 2px 4px rgba(78,78,78,.50);

        @apply .text-6xl .leading-tight;
    }

    &--light {
        #{$c} {
            &__title {
                @apply .text-white;
            }
        }
    }

    &--dark {
        #{$c} {
            &__title {
                @apply .text-secondary;
            }
        }
    }
}
