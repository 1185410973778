h1,
h2,
h3,
h4,
h5 {
    @apply .font-primary .leading-normal .text-secondary .font-semibold;
}


h1 {
    @apply .text-4xl;
}

h2 {
    @apply .text-xl;
}

h3 {
    @apply .text-lg;
}

h4 {
    @apply text-base;
}

h5 {
    @apply .text-xbase;
}
