.c-footer {
    $c: &;

    @apply .bg-tertiary-lightest .text-0 .pt-20 .pb-12;

    &__top {
        ul {
            li {
                @apply .text-sm;
            }
        }
    }

    &__bottom {
        @apply .mt-16;
    }
}
